// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-404-js": () => import("./../../../src/templates/404/404.js" /* webpackChunkName: "component---src-templates-404-404-js" */),
  "component---src-templates-aid-aid-js": () => import("./../../../src/templates/aid/Aid.js" /* webpackChunkName: "component---src-templates-aid-aid-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/Blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-campaign-campaign-js": () => import("./../../../src/templates/campaign/Campaign.js" /* webpackChunkName: "component---src-templates-campaign-campaign-js" */),
  "component---src-templates-day-day-js": () => import("./../../../src/templates/day/Day.js" /* webpackChunkName: "component---src-templates-day-day-js" */),
  "component---src-templates-feminism-feminism-js": () => import("./../../../src/templates/feminism/Feminism.js" /* webpackChunkName: "component---src-templates-feminism-feminism-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/Home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-we-we-js": () => import("./../../../src/templates/we/We.js" /* webpackChunkName: "component---src-templates-we-we-js" */)
}

